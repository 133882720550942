import React, { Component, useState, useEffect } from "react";

import CSVReader from '../../components/CSVReader';
import classNames from "classnames";

import { useNavigate } from "react-router-dom";


const ProductImport: React.FC = () => {
    
    return (

        <div className="container-fluid">

            <h1 className="h3 mb-2 text-gray-800">Import products.</h1>
            <p className="mb-4">Here is the place you can update the test database before monitoring them.
            </p>

            <div className="row mb-4">
                <div className="col">
                    <div className="card shadow mb-8">

                        <div id="CSVReader" className="card-body">
                            <CSVReader type="products" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ProductImport);