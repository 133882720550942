import axios, {AxiosInstance} from "axios";

import AuthConfigMapper from "../AuthConfigMapper";
import {Health, Test, User, UserFromList, DashboardType, Partner, SelectedFilters, Filters} from "../../types/types"


class Server {
  private _axiosInstance: AxiosInstance;

  constructor() {
    this._axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_SERVER_ENDPOINT || "",
      headers: {
        "x-api-key": process.env.REACT_APP_SERVER_API_KEY || "",
        accept: "application/json",
      },
    });

    this._axiosInstance.interceptors.request.use(
      (config) => {
        
        const access_token = Server.user?.access_token;
        config.headers = config.headers || {};
        config.headers["Authorization"] = `Bearer ${access_token}`;
        return config;
      },
      (error) => {
        console.log(`Request interceptors : ${error}`);
        return error;
      }
    );

    this._axiosInstance.interceptors.response.use(
      (response) => {
        if ([200, 201, 204].indexOf(response.status) === -1) {
          return Promise.reject(response.data);
        }
        return response;
      },
      (error) => {
        console.log(`Response interceptors : ${error}`);
        return error;
      }
    );
  }

  static get user(): User | undefined {
    
    // Bug à corriger ici, workaround en place via "found"
    // Le premier appel à Dashboard après la connexion nous renvoie une 403 (Bearer undefined), les infos de connexions ne sont pas assez rapidement dispo dans le sessionStorage

    //console.log("getUser START:" + new Date().getTime())
    const oidcConf = AuthConfigMapper.lastSettings;

      if (oidcConf) {
      const oidcStorage = localStorage.getItem(`oidc.user:${oidcConf.authority}:${oidcConf.client_id}`);
      if (oidcStorage) {
        return JSON.parse(oidcStorage);
      }
    }

    return undefined;
  }

  getUser(): User | undefined {
    return Server.user;
  }

  getHealth(): Promise<Health> {
    return this._axiosInstance
      .get<Health>(`/health`, {
        headers: {"Content-Type": "application/json"},
      })
      .then(({data}) => data);
  }

  getAllUsers(): Promise<UserFromList[] | undefined> {
    return this._axiosInstance
      .get<UserFromList[] | undefined>(`/users/all`, {
        headers: {"Content-Type": "application/json"},
      })
      .then(({data}) => data);
  }

  getPartners(): Promise<Partner[] | undefined> {
    return this._axiosInstance
      .get<Partner[] | undefined>(`/partners`, {
        headers: {"Content-Type": "application/json"},
      })
      .then(({data}) => data);
  }

  getAllPartners(): Promise<Partner[] | undefined> {
    return this._axiosInstance
      .get<Partner[] | undefined>(`/partners/all`, {
        headers: {"Content-Type": "application/json"},
      })
      .then(({data}) => data);
  }

  getFilters(): Promise<Filters | undefined> {
    return this._axiosInstance
      .get<Filters | undefined>(`/filters`, {
        headers: {"Content-Type": "application/json"},
      })
      .then(({data}) => data);
  }

  addUser(sub: string | undefined, partner_id: number | undefined) : Promise<boolean> {
    return this._axiosInstance
      .post<boolean>(`/users/create`,
      { uid : sub, partner_id:  partner_id},
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }

  updateTestsDb(tests: any, partner_id: number): Promise<boolean> {
    //console.log(tests)
    return this._axiosInstance
      .post<boolean>(`/tests/${partner_id}`,
      tests,
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }
  updateProductsDb(products: any, partner_id: number): Promise<any> {
    //console.log(tests)
    return this._axiosInstance
      .post<boolean>(`/products/${partner_id}`,
      products,
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }
  

  getTests(): Promise<Test[]> {
    //console.log(tests)
    return this._axiosInstance
      .get<Test[]>(`/tests/all`,
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }

  getTest(testId:string|undefined): Promise<Test> {
    return this._axiosInstance
      .get<Test>(`/tests/${testId}`,
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }
  
  inspectTest(testId:string|undefined): Promise<Test> {
    return this._axiosInstance
      .get<Test>(`/tests/inspect/${testId}`,
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }

  getDashboard(filters : SelectedFilters): Promise<DashboardType> {
    return this._axiosInstance
      .post<DashboardType>(`/dashboard`,
      filters,
      {
        headers: {"Content-Type": "application/json"}
      })
      .then(({data}) => data);
  }
}

export default new Server();