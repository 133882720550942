import React, { Component, useState, useEffect } from "react";
import { Partner, UserFromList } from "../../types/types";
import Server from "../../core/api/server"

import classNames from "classnames";

import { useFormAction, useNavigate } from "react-router-dom";

const Users: React.FC = () => {

    const [users, setUsers] = useState<UserFromList[]>();
    const [uid, setUid] = useState<string>("");
    const [partnerId, setPartnerId] = useState<number>();
    const [partners, setPartners] = useState<Partner[]>();
    const [error, setError] = useState<string>("");

    //const [data, setData] = useState(null);
    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        Server.getAllUsers().then((response) => {
            setUsers(response);
        });

        Server.getAllPartners().then((response) => {
            setPartners(response);
        });
    }, []);
    
    function submitNewUser() {
        
        let validUid = new RegExp('^[a-zA-Z]{6}[0-9]{2}$')
        if (!validUid.test(uid) || (!partnerId || partnerId ==-1))
            setError("An error occured");
        else {
            Server.addUser(uid, partnerId).then((response) => {
                setIsAdded(response);
                if (response === undefined) {
                    setError("Something went wrong. Please make sure the user is not already registered in the database.");
                } else {
                    window.location.reload();
                }
            });
        }
      }

      const handleChange = (event:any) => {
        setPartnerId(event.target.value);
      };

      function catchUid (event:any) {
        setError("");
        setUid(event.target.value)
      }
    return (

        <div className="container-fluid">

            <h1 className="h3 mb-2 text-gray-800">Users management</h1>
            <p className="mb-4">Here is the list of whitelisted users. You can add new ones if you want (but keep in mind this tool is not scalable yet).</p>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Whitelisted users</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_length" id="dataTable_length">
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-bordered dataTable" id="dataTable" width="100%" role="grid" aria-describedby="dataTable_info">
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting sorting_asc" aria-controls="dataTable" aria-sort="ascending" aria-label="Name: activate to sort column descending">UID</th>
                                                <th className="sorting" aria-controls="dataTable" aria-label="Position: activate to sort column ascending">Role</th>
                                                <th className="sorting" aria-controls="dataTable" aria-label="Office: activate to sort column ascending">Last visit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users?.map(user => (
                                                <tr key={user.uid} className="odd">
                                                    <td className="sorting_1">{user.uid}</td>
                                                    <td>{user.is_admin}</td>
                                                    <td>{user.last_seen}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classNames("card shadow mb-4", error!="" ? "border-bottom-danger" : "")}>
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Add new user</h6>
                </div>
                <div className={classNames("card-body", (0 > 0) ? "border-bottom-danger" : "")}>
                    <div className="table">
                        <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" onChange={event => catchUid(event)} className="form-control form-control-user small-input inline" id="newUserUidInput" placeholder="ex: AVERHE22"  width="50px" maxLength={8} />
                                        <select className="form-control form-control-user small-input inline" value={partnerId} onChange={handleChange}>
                                            <option value="-1"></option>
                                            { partners?.map((partner) => (
                                                <option key={partner.partner_id} value={partner.partner_id}>{partner.name}</option>
                                            ))}
                                        </select>
                                        <a href="#" onClick={submitNewUser} className="btn btn-primary inline btn-adjust ">
                                            <span className="text">Add</span>
                                        </a>
                                    </div>
                                    { error != "" && <p>{error}</p>}     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default React.memo(Users);