import React, { useEffect } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppContent from "./AppContent";
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthConfigMapper } from "./core";


const oidcConfig = AuthConfigMapper.getFedidOidcConfig({
  client_id: AuthConfigMapper.fedidClientId,
  environment: AuthConfigMapper.environment,
  scope: "openid profile",
  redirect_uri: `${window.location.origin}`,
  userStore: new WebStorageStateStore({ store: window.localStorage })
});

const userManager = new UserManager({
  ...oidcConfig,
});

const App: React.FC = () => {
  useEffect(() => {
    // Écouter l'événement addUserLoaded pour être notifié lorsque l'utilisateur est chargé avec succès
    userManager.events.addUserLoaded((user) => {
      //console.log('L\'utilisateur est chargé avec succès :', user);
      window.location.reload();
      // Maintenant, vous pouvez effectuer des actions après le chargement de l'utilisateur, par exemple, effectuer des appels API
    });
  }, []); // Exécutez cet effet une seule fois après le premier rendu

  return (
    <AuthProvider {...oidcConfig}>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;

// TODO : Remove classnames ?