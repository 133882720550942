import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Sidebar, Navbar, Footer, LogoutModal} from "./components";
import Dashboard from "./pages/Dashboard";
import TestImport from "./pages/Tests/Import";
import TestMonitoring from "./pages/Tests/Monitoring";
import TestView from "./pages/Tests/View";
import Users from "./pages/Users";
import ProductImport from "./pages/Products";
import Http403 from "./pages/Errors/Http403";
import {useAuth} from "react-oidc-context";
import {useEffect, useState} from "react";
import { Link } from "react-router-dom";

const AppContent: React.FC = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      //console.log(location)
      if (location.search.includes("code=") || (location.pathname == "/"))
        navigateTo("/dashboard");
    } else {
      if (!(auth.activeNavigator || auth.isLoading)) {
        navigateTo("/403");
        auth.signinRedirect();
      }
    }
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="App">
        <div id="wrapper">
        <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar />
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/tests" element={<TestImport />} />
                      <Route path="/supervision" element={<TestMonitoring />} />
                      <Route path="/tests/:testId" element={<TestView />} />
                      <Route path="/products" element={<ProductImport />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/403" element={<Http403 />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </div>

        <Link className="scroll-to-top rounded" to="#page-top">
        <i className="fas fa-angle-up"></i>
        </Link>
        <LogoutModal />
    </div>
  );
};

export default AppContent;