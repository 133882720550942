import {OidcClientSettings, WebStorageStateStore} from "oidc-client-ts";

export type Configuration = {
  environment: "production" | "preproduction";
  client_id: string;
  scope: string;
  redirect_uri: string;
  userStore : WebStorageStateStore
};

export default class AuthConfigMapper {
  static _lastClientSettings?: OidcClientSettings;

  static get lastSettings(): OidcClientSettings | undefined {
    return this._lastClientSettings;
  }

  static get fedidClientId(): string {
    //console.log(`CLIENT_ID : ${process.env.REACT_APP_FEDID_CLIENT_ID}`)
    return process.env.REACT_APP_FEDID_CLIENT_ID || "";
  }

  static get environment(): "production" | "preproduction" {
    //console.log(`ENV : ${process.env.REACT_APP_AUTH_ENV}`)
    return process.env.REACT_APP_AUTH_ENV === "prod"
      ? "production"
      : "preproduction";
  }
  

  static getFedidOidcConfig({
    client_id,
    scope,
    redirect_uri,
    environment,
    userStore
  }: Configuration): OidcClientSettings {
    const isPreprodAuthEnv = environment !== "production";

    const settings = {
      client_id,
      authority: `https://${
        isPreprodAuthEnv ? "preprod." : ""
      }idpdecathlon.oxylane.com`,
      scope,
      loadUserInfo: true,
      redirect_uri,
      userStore
    };

    this._lastClientSettings = settings;
    return settings;
  }
}