import React, { useState, useEffect } from "react";
import Server from "../../core/api/server";
import { Partner, Country, User, Family } from "../../types/types";
import { useAuth } from "react-oidc-context";

const Navbar: React.FC = () => {
  const {isAuthenticated} = useAuth()
  const [user, setUser] = useState<User | undefined>(undefined);
  const [partners, setPartners] = useState<Partner[] | undefined>(undefined);
  const [selectedPartner, setSelectedPartner] = useState<string|undefined>(undefined);
  const [countries, setCountries] = useState<Country[] | undefined>(undefined);
  const [selectedCountry, setSelectedCountry] = useState<string|undefined>(undefined);
  const [families, setFamilies] = useState<Family[] | undefined>(undefined);
  const [selectedFamily, setSelectedFamily] = useState<string|undefined>(undefined);


  function undefineOnEmptyString(str: string) : string | undefined {
    return (str != "") ? str : undefined;
  }

  const handlePartnerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = undefineOnEmptyString(event.target.value);
    setSelectedPartner(selectedValue);
    const filters = localStorage.getItem("filters");
    let new_filters = {
      partner: selectedValue,
      country: undefined,
      family: undefined
    }
    if (filters) { 
      new_filters.country = JSON.parse(filters).country
      new_filters.family = JSON.parse(filters).family
    }
    localStorage.setItem("filters", JSON.stringify(new_filters));
    window.dispatchEvent(new Event("storage"));
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = undefineOnEmptyString(event.target.value);
    setSelectedCountry(selectedValue);
    const filters = localStorage.getItem("filters");
    let new_filters = {
      partner: undefined,
      country: selectedValue,
      family: undefined
    }
    if (filters) {
      new_filters.partner = JSON.parse(filters).partner
      new_filters.family = JSON.parse(filters).family
    }
    localStorage.setItem("filters", JSON.stringify(new_filters));
    window.dispatchEvent(new Event("storage"));
  };

  const handleFamilyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = undefineOnEmptyString(event.target.value);
    setSelectedFamily(selectedValue);
    const filters = localStorage.getItem("filters");
    let new_filters = {
      partner: undefined,
      country: undefined,
      family: selectedValue
    }
    if (filters) {
      new_filters.partner = JSON.parse(filters).partner
      new_filters.country = JSON.parse(filters).country
    }
    localStorage.setItem("filters", JSON.stringify(new_filters));
    window.dispatchEvent(new Event("storage"));
  };

  useEffect(() => {
    const fetchData = async () => {
      const us = Server.getUser();
      setUser(us);

      try {
        const filters = await Server.getFilters()
        setPartners(filters?.partners);
        setCountries(filters?.countries);
        setFamilies(filters?.families);
      } catch (error) {
        console.error("Error fetching filters:", error);
      }

      // On récupère ce qui était dans le localstorage
      const storedFilters = localStorage.getItem("filters");
      if (storedFilters) {
        setSelectedPartner(JSON.parse(storedFilters).partner);
        setSelectedCountry(JSON.parse(storedFilters).country);
        setSelectedFamily(JSON.parse(storedFilters).family);
      }
    };

    if (isAuthenticated) fetchData();

    const handleStorageChange = () => {
      const storedFilters = localStorage.getItem("filters");
      if (storedFilters) {
        setSelectedPartner(JSON.parse(storedFilters).partner);
        setSelectedCountry(JSON.parse(storedFilters).country);
        setSelectedFamily(JSON.parse(storedFilters).family);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [isAuthenticated]);

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>
      <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <select
            id="selected_partner"
            name="selected_partner"
            className="form-control bg-light border-0 small"
            value={selectedPartner}
            onChange={handlePartnerChange}
          >
            <option value="">All partners</option>
            {partners?.map((partner) => (
              <option key={partner.partner_id} value={partner.partner_id}>
                {partner.name}
              </option>
            ))}
          </select>
        </div>
      </form>

      <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <select
            id="selected_country"
            name="selected_country"
            className="form-control bg-light border-0 small"
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            <option value="">All countries</option>
            {countries?.map((country) => (
              <option key={country.country} value={country.country}>
                {country.country}
              </option>
            ))}
          </select>
        </div>
      </form>
      
      <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <select
            id="selected_family"
            name="selected_family"
            className="form-control bg-light border-0 small"
            value={selectedFamily}
            onChange={handleFamilyChange}
          >
            <option value="">All families</option>
            {families?.map((family) => (
              <option key={family.id} value={family.id}>
                {family.id} - {family.name}
              </option>
            ))}
          </select>
        </div>
      </form>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow">
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {user?.profile.cn} ({user?.profile.uid})
            </span>
            <img className="img-profile rounded-circle" src={user?.profile.photourl} />
          </a>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default React.memo(Navbar);
