import React, {Component, useState, useEffect} from "react";
import Status from "./Status"
import Server from "../../core/api/server"
import { User } from "oidc-client-ts";
import { Link } from "react-router-dom";


const Sidebar: React.FC = () => {

    const [user, setUser] = useState<User | undefined>();

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

          <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="index.html">
              <div className="sidebar-brand-icon rotate-n-15">
                  <i className="fas fa-laugh-wink"></i>
              </div>
              <div className="sidebar-brand-text mx-3">Purchase tracker<sup>2</sup></div>
          </Link>

          <hr className="sidebar-divider my-0" />

          <li className="nav-item active">
              <Link className="nav-link" to="/dashboard">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span>Dashboard</span></Link>
          </li>

          <hr className="sidebar-divider"/>

          
          
          <div className="sidebar-heading">
              Lizee / Booxi
          </div>
          <li className="nav-item">
            <Link className="nav-link" to="/supervision">
                <i className="fas fa-fw fa-list"></i>
                <span>Monitoring</span></Link>
          </li>
          <li className="nav-item">
          <Link className="nav-link" to="/tests">
                <i className="fas fa-fw fa-upload"></i>
                <span>Import test file</span></Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/products">
                <i className="fas fa-fw fa-upload"></i>
                <span>Import product file</span></Link>
          </li>
          <hr className="sidebar-divider" />

          <div className="sidebar-heading">
              Admin
          </div>

          
          <li className="nav-item">
              <Link className="nav-link" to="/users">
                  <i className="fas fa-fw fa-users"></i>
                  <span>Users</span></Link>
          </li>

          <hr className="sidebar-divider d-none d-md-block" />
          
          <Status />
      </ul>
  );
};

export default React.memo(Sidebar);