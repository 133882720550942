import React, { useState, useEffect } from "react";
import { Health } from "../../../types/types"
import Server from "../../../core/api/server"

const Status: React.FC = () => {
    const [healthStatus, setHealthStatus] = useState<Health>();

    useEffect(() => {
        Server.getHealth().then((response) => {
            setHealthStatus(response);

        });
    }, []);

    return (
        <div className="text-center d-none d-md-inline">API health:&nbsp; 
            {healthStatus?.status}
        </div>
    );
};

export default React.memo(Status);