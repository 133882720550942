import React from "react";
import {useNavigate} from "react-router-dom";

const Http403: React.FC = () => {
    return (
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">403</h1>
                <p>That's a problem</p>
            </div>
        </div>
    );
};

export default React.memo(Http403);