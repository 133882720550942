import React, { useState, useEffect } from "react";
import Server from "../../../core/api/server";
import { Test } from "../../../types/types";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";


const getInitialFilters = () => {
    try {
      const filters = JSON.parse(localStorage.getItem("filters") || "{}");
      return {
        partner: filters.partner || undefined,
        country: filters.country || undefined,
        family: filters.family || undefined,
      };
    } catch (error) {
      return {
        partner: undefined,
        country: undefined,
        family: undefined,
      };
    }
  };

  function getTransactionLink(test : Test) {
    if (test.transaction) {
        if (test.transaction.order_number != null) {
            switch(test.country) {
                case null : 
                    return(`${process.env.REACT_APP_SQUARE_URL}/fr/order/details?order=${test?.transaction.order_number}`)
                    break;
                default :
                    return(`${process.env.REACT_APP_SQUARE_URL}/${test?.country.toLowerCase()}/order/details?order=${test?.transaction.order_number}`)
            }
        }
        else return(`${process.env.REACT_APP_POSLOG_URL}/transaction/${test?.transaction?.poslog_id}/anonymized/xml`)
    }
    return undefined
}

function getCustomerLink(test : Test) {
    let lang : string = ""
    if (test.member_id) {
        switch(test.country) {
            case null :
                lang = "fr"
                break;
            default :
                lang = test?.country.toLowerCase()
        }
        return(`${process.env.REACT_APP_SQUARE_URL}/${lang}/customer/profile?userId=${test?.member_id}`)
    }
    return(`${process.env.REACT_APP_SQUARE_URL}`)
}

const TestMonitoring: React.FC = () => {
    var moment = require('moment-timezone');
    moment.locale("fr");

    const [tests, setTests] = useState<Test[]>();
    const [displayedTests, setDisplayedTests] = useState<Test[]>();
    const [searchString, setSearchString] = useState<string>("");
    const [showSuccessOnly, setShowSuccessOnly] = useState<boolean>(false);
    const [showMembersOnly, setShowMembersOnly] = useState<boolean>(false);

    const [selectedPartner, setSelectedPartner] = useState<string | undefined>(getInitialFilters().partner);
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>(getInitialFilters().country);
    const [selectedFamily, setSelectedFamily] = useState<string | undefined>(getInitialFilters().family);

    useEffect(() => {
        Server.getTests().then((response) => {
            setTests(response);
        });
    }, []);

    // Quand test change, on modifie les tests affichés
    useEffect(() => {
        filterTests();
    }, [tests, selectedPartner, selectedCountry, selectedFamily, searchString, showSuccessOnly, showMembersOnly]);

    // Quand la valeur change dans le localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            setSelectedPartner(getInitialFilters().partner);
            setSelectedCountry(getInitialFilters().country);
            setSelectedFamily(getInitialFilters().family);
        };
        window.addEventListener("storage", handleStorageChange);
        return () => { window.removeEventListener("storage", handleStorageChange);};
    }, []);

    const filterTests = () => {
        try {
                    setDisplayedTests(
                    tests?.filter((t) =>
                        ((showSuccessOnly && t.transaction != undefined) || !showSuccessOnly) &&
                        ((showMembersOnly && t.member_id != null) || !showMembersOnly) &&
                        (t.test_id.toString().includes(searchString) ||
                            t.external_id.includes(searchString) ||
                            t.member_name.toString().includes(searchString) ||
                            t.member_email.toString().includes(searchString)) &&
                        ((selectedPartner === null || !selectedPartner) || t.partner_id.toString() === selectedPartner) &&
                        ((selectedCountry === null || !selectedCountry) || t.country === selectedCountry) && 
                        ((selectedFamily === null || !selectedFamily) || (t.products && t.products.filter(p => (p.family_id && p.family_id.toString() === selectedFamily.toString())).length > 0))
                    )
                );
        } catch(e) {console.log(e)}
    };

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-2 text-gray-800">Test supervision</h1>
            <p className="mb-4">
                This is where you can see all tests imported in PurchaseTracker. Please note that only tests imported in purchaase tracker are displayed here, some recent ones might exists in Lizee if you have not made any update recently.
            </p>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Test list (PurchaseTracker database)</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_length" id="dataTable_length">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setShowSuccessOnly(!showSuccessOnly)}
                                        />
                                        <label>Show with transactions only</label>
                                        <br/>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setShowMembersOnly(!showMembersOnly)}
                                        />
                                        <label>Show with members only</label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div id="dataTable_filter" className="dataTables_filter">
                                        <label>
                                            Search:
                                            <input
                                                onChange={(e) => setSearchString(e.target.value)}
                                                type="search"
                                                className="form-control form-control-sm"
                                                placeholder=""
                                                aria-controls="dataTable"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <table
                                        className="table table-bordered dataTable"
                                        id="dataTable"
                                        width="100%"
                                        role="grid"
                                        aria-describedby="dataTable_info"
                                        style={{ width: "100%" }}
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th></th>
                                                <th></th>
                                                <th>External ID</th>
                                                <th>Test start date</th>
                                                <th>Product count</th>
                                                <th>Member</th>
                                                <th>Transaction (EUR)</th>
                                                <th>Purchase in family (EUR)</th>
                                                <th>Other from sport (EUR)</th>
                                                <th>Others (EUR)</th>
                                                <th>Last inspect.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayedTests && displayedTests.length > 0 ? (
                                                displayedTests.map((test, index) => (
                                                    <tr className={index % 2 === 0 ? "odd" : "even"} key={test.test_id}>
                                                        <td>{test.member_id != null ? <Link to={getCustomerLink(test)} target="_blank"><i className="fas fa-fw fa-user-check"></i></Link> : null }</td>
                                                        <td>{(test.transaction)
                                                            ? <Link to={`${getTransactionLink(test)}`} target="_blank"><i className="fas fa-fw fa-shopping-cart"></i></Link>
                                                            : "" }</td>
                                                        <td>
                                                            <Link to={`/tests/${test.test_id}`}>{test.external_id}</Link>
                                                        </td>
                                                        <td>{moment(test.start_date).format("l")}</td>
                                                        <td>{test.products?.length}</td>
                                                        <td>{test.member_name}</td>
                                                        <td>
                                                            <Link
                                                                target="_blank"
                                                                to={`${process.env.REACT_APP_POSLOG_URL}/transaction/${test?.transaction?.poslog_id}/anonymized/xml`}
                                                                title={`${test?.transaction?.poslog_id}`}
                                                            >
                                                                {test?.transaction?.total_amount.toFixed(2)}
                                                            </Link>
                                                        </td>
                                                        {/*<td>{test.transaction?.model_amount}</td>*/}
                                                        <td>{test.transaction?.family_amount.toFixed(2)}</td>
                                                        <td>
                                                            {test.transaction
                                                                ? (
                                                                      (test.transaction?.sport_amount || 0) -
                                                                      (test.transaction?.family_amount || 0)
                                                                  ).toFixed(2)
                                                                : ""}
                                                        </td>
                                                        <td>
                                                            {test.transaction
                                                                ? (
                                                                      (test.transaction?.total_amount || 0) -
                                                                      (test.transaction?.sport_amount || 0)
                                                                  ).toFixed(2)
                                                                : ""}
                                                        </td>
                                                        <td>
                                                            {test.last_inspection != null
                                                                ? moment(test.last_inspection).fromNow(true)
                                                                : "n/a"}{" "}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className="odd">
                                                    <td colSpan={10}>There is zero test to inspect yet.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(TestMonitoring);
