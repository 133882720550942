import React, { useState, useEffect, ReactElement } from "react";
import Papa from "papaparse";
import Server from "../../core/api/server";
import { Partner } from "../../types/types";

type CSVReaderProps = {
  type: string;
};


const CSVReader: React.FC<CSVReaderProps> = ({ type }) => {
  const [jsonData, setJsonData] = useState([]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [partners, setPartners] = useState<Partner[] | undefined>(undefined);
  const [selectedPartner, setSelectedPartner] = useState<string | undefined>(undefined);

  let prompt: ReactElement = <p>Error: No CSVReader param</p>;
  let hint: ReactElement = <p className="mb-4">(Waiting for partner to be selected)</p>;

  switch (type) {
    case "tests":
      switch (selectedPartner) {
        case "1":
          prompt = (
            <p className="mb-4">
              Choose your partner and insert *.csv file extracted from{" "}
              <a href="https://rms.lizee.io/oms-stats" target="_blank">
                rms.lizee.io/oms-stats
              </a>{" "}
              here.
            </p>
          );
          hint = (
            <p className="hint">
              Expected formatting : <br />
              "Numero de commande (sylius)","Numero de commande (rms)","Date de creation de la commande","Date de debut de la commande","Date de fin de la commande","Email client","Prenom client","Nom client","Nombre d'articles dans la commande","Liste des produits de la commande","Montant des produits de la commande TTC","Montant de la commande TTC","Montant de la commande HT","Montant de la TVA de la commande","Factures","Statut de la commande (Validee, Annulee, etc)","Poids de la commande (kg)","Mode de livraison (domicile/point relais)","Transporteur","Type de ByPass","Entrepot","Frais Stripe","Code promo","Montant de la réduction","ID du point relais/magasin","Durée de location (jours)","Adresse de livraison","Adresse de facturation)","Remboursement"
            </p>
          );
          break;
        case "2":
          prompt = <p className="mb-4">Choose your partner and insert *.csv file extracted from Booxi here.</p>;
          hint = (
            <p className="hint">
              Expected formatting : <br />
              "Numero de commande (sylius)","Numero de commande (rms)","Date de creation de la commande","Date de debut de la commande","Date de fin de la commande","Email client","Prenom client","Nom client","Nombre d'articles dans la commande","Liste des produits de la commande","Montant des produits de la commande TTC","Montant de la commande TTC","Montant de la commande HT","Montant de la TVA de la commande","Factures","Statut de la commande (Validee, Annulee, etc)","Poids de la commande (kg)","Mode de livraison (domicile/point relais)","Transporteur","Type de ByPass","Entrepot","Frais Stripe","Code promo","Montant de la réduction","ID du point relais/magasin","Durée de location (jours)","Adresse de livraison","Adresse de facturation)","Remboursement"
            </p>
          );
          break;
        default:
      }
      break;
    case "products":
      switch (selectedPartner) {
        case "1":
          prompt = (
            <p className="mb-4">
              Choose your partner and insert *.csv file extracted from{" "}
              <a href="https://rms.lizee.io/stock-stats" target="_blank">
                rms.lizee.io/stock-stats
              </a>{" "}
              here.
            </p>
          );
          hint = (
            <p className="hint">
              Expected formatting <br />
              "Appt. ID","Client Name","Client Email","Client Phone","Client Mobile","Location","Address","Event Date","Event Time","Duration","Status","Subtotal","Tax 1","Tax 2","Total","Transaction Fee","Staff Name","Service Name 1","Service Duration 1","Service Spacing 1","Pre Booking Question 1","Pre Booking Answer 1","Store ID","Store Number","Store Name","Store City","Store Postal or Zip Code","Store Country"
            </p> 
          );
          break;
        case "2":
          prompt = <p className="mb-4">Choose your partner and insert *.csv file extracted from Booxi here.</p>;
          hint = (
            <p className="hint">
              Expected formatting <br />
              "Product Code","Product Reference Name"
            </p>
          );
          break;
        default:
      }
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await Server.getPartners();
      setPartners(response);
      const filters = localStorage.getItem("filters");
      if (filters) setSelectedPartner(JSON.parse(filters).partner);
    };

    fetchData();

    const handleStorageChange = () => {
      const filters = localStorage.getItem("filters");
      if (filters) setSelectedPartner(JSON.parse(filters).partner);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const processCsvData = async () => {
      if (jsonData.length > 0) {
        // On regarde si le type de fichier est celui attendu (entêtes OK)
        const checkFields = (fields: string[]) => {
          return fields.every((field) => jsonData[0][field] !== undefined && jsonData[0][field] !== null);
        };

        let fields: string[] = [""];
        switch (selectedPartner) {
          case "1":
            switch (type) {
              case "tests":
                fields = [
                  "Numero de commande (sylius)",
                  "Numero de commande (rms)",
                  "Date de creation de la commande",
                  "Date de debut de la commande",
                  "Date de fin de la commande",
                  "Email client",
                  "Prenom client",
                  "Nom client",
                  "Nombre d'articles dans la commande",
                  "Liste des produits de la commande",
                  "Montant des produits de la commande TTC",
                  "Montant de la commande TTC",
                  "Montant de la commande HT",
                  "Montant de la TVA de la commande",
                  "Factures",
                  "Statut de la commande (Validee, Annulee, etc)",
                  "Poids de la commande (kg)",
                  "Mode de livraison (domicile/point relais)",
                  "Transporteur",
                  "Type de ByPass",
                  "Entrepot",
                  "Frais Stripe",
                  "Code promo",
                  "Montant de la réduction",
                  "ID du point relais/magasin",
                  "Durée de location (jours)",
                  "Adresse de livraison",
                  "Adresse de facturation)",
                  "Remboursement",
                ];
                break;
              case "products":
                fields = [
                  "PUID",
                  "Product Reference Name",
                  "Product Code",
                  "SKU",
                  "EANs",
                  "Storage Location",
                  "Status",
                  "Type",
                  "Customer",
                  "Created At",
                  "Updated At",
                  "Rotations",
                  "Rented Days",
                  "Estimated Refit Time",
                  "Average Refit Time",
                  "Nb Photos",
                  "Quality",
                ];
                break;
            }
            break;
          case "2":
            switch (type) {
              case "tests":
                fields = [
                  "Appt. ID",
                  "Client Name",
                  "Client Email",
                  "Client Phone",
                  "Client Mobile",
                  "Location",
                  "Address",
                  "Event Date",
                  "Event Time",
                  "Duration",
                  "Status",
                  "Subtotal",
                  "Tax 1",
                  "Tax 2",
                  "Total",
                  "Transaction Fee",
                  "Staff Name",
                  "Service Name 1",
                  "Service Duration 1",
                  "Service Spacing 1",
                  "Pre Booking Question 1",
                  "Pre Booking Answer 1",
                  "Store ID",
                  "Store Number",
                  "Store Name",
                  "Store City",
                  "Store Province or State",
                  "Store Postal or Zip Code",
                  "Store Country"

                ];
                break;
              case "products":
                fields = [
                  "Product Code",
                  "Product Reference Name"];
                break;
            }
            break;
        }

        if (!checkFields(fields)) {
          //console.log("Error: The CSV header does not match what we are waiting for...");
          setMessage("Error: It's seems the CSV is not what we are looking for.")
          return;
        }
      }
    };

    processCsvData();
  }, [jsonData]);

  function undefineOnEmptyString(str: string) : string | undefined {
    return (str != "") ? str : undefined;
  }

  const handlePartnerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = undefineOnEmptyString(event.target.value);
    setSelectedPartner(selectedValue);
    const filters = localStorage.getItem("filters");
    let new_filters = {
      partner: selectedValue,
      country: undefined,
      family: undefined
    }
    if (filters) { 
      new_filters.country = JSON.parse(filters).country
      new_filters.family = JSON.parse(filters).family
    }
    localStorage.setItem("filters", JSON.stringify(new_filters));
    window.dispatchEvent(new Event("storage"));
  };

  const handleFileUpload = (event: any) => {
    if (selectedPartner !== "-1") {
      const fileExtension = getExtension(event.target.value.toLowerCase());
      if (fileExtension === "csv") {
        const file = event.target.files[0];
        Papa.parse(file, {
          complete: (result: any) => {
            setJsonData(result.data);
          },
          header: true, // Indique que la première ligne du CSV est l'en-tête
        });

        // On verifie le format des fichiers ici

      } else {
        setMessage("It's not a valid file (*.csv expected)");
        setJsonData([]);
      }
    } else {
      console.log("Please select a partner before uploading a file.");
      setMessage("Please select a partner before uploading a file.");
    }
  };

  const sendJsonToBack = async (event: any) => {
    event.preventDefault();
    if (jsonData.length === 0) {
      setMessage("No data to send.");
      return;
    }
    try {
      let response;
      if (type === "tests") {
        // On cleane le json pour ne pas flooder
        switch(selectedPartner) {
          case "1":
            response = await Server.updateTestsDb(jsonData, Number(selectedPartner));
            break;
          case "2":
            const filteredJSON = jsonData.filter(j => {
              const serviceName = j["Service Name 1"];
              return (
                /test/i.test(serviceName) &&        // Vérifie si "test" (insensible à la casse) est présent
                /\d{7}$/.test(serviceName)          // Vérifie si les 7 derniers caractères sont des chiffres
              );
            });
            response = await Server.updateTestsDb(filteredJSON, Number(selectedPartner));
            break
        }

        
      } else if (type === "products") {
        response = await Server.updateProductsDb(jsonData, Number(selectedPartner));
      }
      const isUpdated = response !== undefined ? response : false;
      setIsUpdated(isUpdated);
      if (!isUpdated) {
        setMessage("Something went wrong.");
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage("Something went wrong.");
      console.error("Error updating database:", error);
    }
  };

  function getExtension(filename: string) {
    return filename.split(".").pop();
  }

  return (
    <div>
      {prompt}
      <h5>Selected partner :</h5>
      <select
        id="selected_partner"
        name="selected_partner"
        className="form-control bg-light border-0 small"
        value={selectedPartner}
        onChange={handlePartnerChange}
      >
        <option value="-1"></option>
        {partners?.map((partner) => (
          <option key={partner.partner_id} value={partner.partner_id}>
            {partner.name}
          </option>
        ))}
      </select>
      <h5>File Upload</h5>
      {selectedPartner !== "-1" ? (
        <input type="file" onChange={handleFileUpload} />
      ) : (
        <p>Waiting for a partner to be selected</p>
      )}
      <hr />
      {hint}
      {message !== "" && <p>{message}</p>}
      <a href="#" className="btn btn-primary btn-icon-split float-right" onClick={sendJsonToBack}>
        <span className="icon text-white-50">
          <i className="fas fa-upload"></i>
        </span>
        <span className="text">Upload to Purchase Tracker</span>
      </a>
    </div>
  );
};

export default CSVReader;
