import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router";
import Server from "../../../core/api/server"
import { Test } from "../../../types/types";

import classNames from "classnames";
import moment from "moment";
import 'moment/locale/fr';

import { Link } from "react-router-dom";

function getTransactionLink(test : Test) {
    if (test.transaction) {
        if (test.transaction.order_number != null) {
            switch(test.country) {
                case null : 
                    return(`${process.env.REACT_APP_SQUARE_URL}/fr/order/details?order=${test?.transaction.order_number}`)
                    break;
                default :
                    return(`${process.env.REACT_APP_SQUARE_URL}/${test?.country.toLowerCase()}/order/details?order=${test?.transaction.order_number}`)
            }
        }
        else return(`${process.env.REACT_APP_POSLOG_URL}/transaction/${test?.transaction?.poslog_id}/anonymized/xml`)
    }
    return undefined
}

function getCustomerLink(test : Test) {
    let lang : string = ""
    if (test.member_id) {
        switch(test.country) {
            case null :
                lang = "fr"
                break;
            default :
                lang = test?.country.toLowerCase()
        }
        return(`${process.env.REACT_APP_SQUARE_URL}/${lang}/customer/profile?userId=${test?.member_id}`)
    }
    return(`${process.env.REACT_APP_SQUARE_URL}`)
}

const TestView: React.FC = () => {
    
    const [test, setTest] = useState<Test>();
    const [inspect, setInspect] = useState<Test>();
    const params = useParams();

    useEffect(() => {
        
        /*Server.getTest(params.testId).then((response) => {
            setTest(response);
        });*/
        
        Server.inspectTest(params.testId).then((response) => {
            setTest(response);
            setInspect(response);
        });

    }, []);

    
    return (

        <div className="container-fluid">

            <h1 className="h3 mb-2 text-gray-800">Test {test?.external_id}</h1>
            <p className="mb-4">Here is all our database informations about the test</p>

            <div className="row">
                <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Test info</h6>
                        </div>
                        <div className="card-body">
                            
                                <table className="table table-bordered dataTable" id="dataTable" width="100%"  role="grid" aria-describedby="dataTable_info">
                                    <tbody>

                                        <tr className="odd">
                                            <td>Internal ID</td>
                                            <td>{test?.test_id}</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Order Sylius</td>
                                            <td>{test?.external_id}</td>
                                        </tr><tr className="odd">
                                            <td>Test period</td>
                                            <td>{moment(test?.start_date).format("ll")} - {moment(test?.end_date).format("ll")}<br/>({moment.duration(test?.test_duration, 'minutes').humanize()})</td>
                                        </tr><tr className="odd">
                                            <td>Tester name</td>
                                            <td>{test?.member_id != null ? <Link to={`${getCustomerLink(test)}`} target="_blank"><i className="fas fa-fw fa-user-check"></i></Link> : null } {test?.member_name} ({test?.member_email})</td>
                                        </tr><tr className="odd">
                                            <td>Last inspection</td>
                                            <td>{moment(test?.last_inspection).format("lll")}</td>
                                        </tr>
                                            
                                    </tbody>
                                </table>
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mb-4">    
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Product(s)</h6>
                        </div>
                        <div className="card-body">
                            <table className="table table-bordered dataTable" id="dataTable" width="100%"  role="grid" aria-describedby="dataTable_info">
                                <tbody>
                                    { (test?.products != undefined && test?.products.length > 0)
                                        ?   test?.products.map(product => (
                                                <tr className="odd" key={test.test_id}>
                                                    <td>{product.code}</td>
                                                    <td>{product.name}</td>
                                                </tr>
                                            ))
                                        :   <tr className="odd">
                                                <td colSpan={2}>There is no product in this test.</td>
                                            </tr>
                                    } 
                                        
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="row">
                
                <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Transaction</h6>
                        </div>
                        <div className="card-body">
                            { test?.transaction != undefined &&
                                <table className="table table-bordered dataTable" id="dataTable" width="100%"  role="grid" aria-describedby="dataTable_info">
                                    <thead>
                                        <tr role="row">
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="odd">
                                            <td>Transaction internal ID</td>
                                            <td>{test?.transaction?.id_transaction}</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>BU</td>
                                            <td>{test?.transaction?.bu_name} ({test?.transaction?.bu})</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Date</td>
                                            <td>{test?.transaction?.purchased_at.toString()}</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Poslog ID</td>
                                            <td><Link target="_blank" to={`${getTransactionLink(test)}`} title={`${test?.transaction?.poslog_id}`}>Found</Link></td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Purchase in family</td>
                                            <td>{test?.transaction?.family_amount.toFixed(2)}</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Additional purchase in same brand</td>   
                                            <td>{((test.transaction?.sport_amount || 0) - (test.transaction?.family_amount || 0)).toFixed(2) }</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Other purchase</td>   
                                            <td>{((test.transaction?.total_amount || 0) - (test.transaction?.sport_amount || 0)).toFixed(2) }</td>
                                        </tr>
                                        <tr className="odd">
                                            <td>Total amount</td>
                                            <td><b>{(test?.transaction?.total_amount).toFixed(2)}</b></td>
                                        </tr>
                                            
                                    </tbody>
                                </table>
                            }
                            { test?.transaction == undefined && 
                                <p>There is no transaction for this test yet.</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Related tests from user</h6>
                        </div>
                        <div className="card-body">
                            { test?.related_tests != undefined &&
                                <table className="table table-bordered dataTable" id="dataTable" width="100%"  role="grid" aria-describedby="dataTable_info">
                                    <thead><tr role="row">
                                            <th></th>
                                            <th>External ID</th>
                                            <th>Test period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { (test.related_tests != undefined && test.related_tests.length > 0)
                                        ?   test.related_tests?.map((t, index) => (
                                            <tr className="odd">
                                                <td>{ (t.transaction ? '🟢' : '')}</td>
                                                <td><Link to={`/tests/${t.test_id}`}>{t.external_id}</Link></td>
                                                <td>{moment(t.start_date).format("l")}-{moment(t.end_date).format("l")}</td>
                                            </tr>

                                            ))
                                            :   <tr className="odd">
                                                    <td colSpan={9}>There is no test to inspect yet.</td>
                                                </tr>
                                        } 
                                    </tbody>
                                </table>
                            }
                            { test?.transaction == undefined && 
                                <p>Nothing to see here</p>
                            }
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default React.memo(TestView);