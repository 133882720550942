import React, {useState} from "react";

const Footer: React.FC = () => {

  return (
    <footer className="sticky-footer bg-white">
        <div className="container my-auto">
            <div className="copyright text-center my-auto">
                <span>Purchase tracker by Digital Labs, launched feb. 2024</span>
            </div>
        </div>
    </footer>
  );
};

export default React.memo(Footer);