import React, { Component, useState, useEffect } from "react";
import Server from "../../core/api/server"
import { DashboardType, Filters } from "../../types/types";
//import {useNavigate} from "react-router-dom";

import moment from "moment";
import 'moment/locale/fr';
import { Route, Link, useNavigate } from "react-router-dom";

const getInitialFilters = () => {
    try {
      const filters = JSON.parse(localStorage.getItem("filters") || "{}");
      return {
        partner: filters.partner || undefined,
        country: filters.country || undefined,
        family: filters.family || undefined,
      };
    } catch (error) {
      return {
        partner: undefined,
        country: undefined,
        family: undefined,
      };
    }
  };

const Dashboard: React.FC = () => {
    var moment = require('moment-timezone');
    moment.locale("fr");

    const [selectedPartner, setSelectedPartner] = useState<string | undefined>(getInitialFilters().partner);
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>(getInitialFilters().country);
    const [selectedFamily, setSelectedFamily] = useState<string | undefined>(getInitialFilters().family);
    const [dashboard, setDashboard] = useState<DashboardType>();

    useEffect(() => {
        Server.getDashboard(getInitialFilters()).then((response) => {
            setDashboard(response);
        });
    }, []);
        
    // Quand test change, on modifie les tests affichés
    useEffect(() => {
        Server.getDashboard(getInitialFilters()).then((response) => {
            setDashboard(response);
        });
    }, [selectedPartner, selectedCountry, selectedFamily])

    // Quand la valeur change dans le localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            setSelectedPartner(getInitialFilters().partner);
            setSelectedCountry(getInitialFilters().country);
            setSelectedFamily(getInitialFilters().family);
        };
        window.addEventListener("storage", handleStorageChange);
        return () => { window.removeEventListener("storage", handleStorageChange);};
    }, []);

    return (
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
            </div>
            <div className="row">
            
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-dark shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Tests in database</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard?.test_count}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-database fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        {dashboard?.identified_test_count} tests with member account</div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{dashboard?.identified_test_rate}</div>
                                        </div>
                                        <div className="col">
                                            <div className="progress progress-sm mr-2">
                                                <div className="progress-bar bg-info" role="progressbar" style={{width: dashboard?.identified_test_rate}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-database fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-dark shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Known members</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard?.tester_count}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-users fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                

                
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">{dashboard?.transaction_count} SUCCESSFUL TESTS
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{dashboard?.test_success_rate}</div>
                                        </div>
                                        <div className="col">
                                            <div className="progress progress-sm mr-2">
                                                <div className="progress-bar bg-info" role="progressbar" style={{width: dashboard?.test_success_rate}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-check fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-danger shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        {dashboard?.transaction_count} transaction(s)</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard?.transaction_total_amount.toFixed(2)} EUR</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Purchase in family</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard?.transaction_family_amount.toFixed(2)} EUR</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-bullseye fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Purchase in sport
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{dashboard?.transaction_sport_amount.toFixed(2)} EUR</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-cart-plus fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-light shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Other purchase
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{dashboard?.transaction_other_amount.toFixed(2)} EUR</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-plus fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Last purchase spotted</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-bordered dataTable" id="dataTable" width="100%" role="grid" aria-describedby="dataTable_info" style={{width: "100%"}}>
                                    <thead>
                                        <tr role="row">
                                            <th>External ID</th>
                                            <th>Member</th>
                                            <th>Total purchase</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { (dashboard && dashboard.last_transactions.length > 0)
                                        ?   dashboard.last_transactions?.map((transaction, index) => (
                                                <tr className="odd">
                                                    <td><Link to={`/tests/${transaction.test_id}`}>{transaction.external_id}</Link></td>
                                                    <td>{transaction.member_name}</td>
                                                    <td>{transaction.transaction?.total_amount.toFixed(2)}</td>
                                                </tr>
                                        ))
                                        :   <tr className="odd">
                                                <td colSpan={3}>There is no purchase to display yet.</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default React.memo(Dashboard);